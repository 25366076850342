import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  TextField,
  Grid,
  Paper,
  IconButton,
  Box,
  CircularProgress,
  Alert,
  styled,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Tooltip,
  Container,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  NavigateNext as NextIcon,
  NavigateBefore as PrevIcon,
  Cancel as CancelIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import data from "./QuoteAssets/image.png";

const AppHeader = styled(AppBar)({
  backgroundColor: "white",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
});

const AppFooter = styled(Box)({
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "white",
  borderTop: "1px solid #eee",
  padding: "16px 0",
  zIndex: 1000,
});

const ContentWrapper = styled(Box)({
  paddingTop: "84px",
  paddingBottom: "80px",
  minHeight: "calc(100vh - 164px)",
  backgroundColor: "#f5f5f5",
});

const ScrollableContent = styled(Box)({
  overflowY: "auto",
  maxHeight: "calc(100vh - 200px)",
  padding: "20px",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#8d8dfd",
    borderRadius: "4px",
  },
});

const StyledPaper = styled(Paper)({
  padding: "24px",
  marginBottom: "24px",
  backgroundColor: "white",
});

const ActionIconButton = styled(IconButton)({
  backgroundColor: "#8d8dfd",
  color: "white",
  margin: "0 8px",
  borderRadius: "4px", // Make the edges less rounded for a rectangular look
  padding: "8px 16px", // Increase padding for rectangular appearance
  "&:hover": {
    backgroundColor: "#7272ff",
  },
  "&.Mui-disabled": {
    backgroundColor: "#d3d3d3",
    color: "#666",
  },
});

const StyledFormControl = styled(FormControl)({
  marginBottom: "16px",
  "& .MuiInputLabel-root": {
    backgroundColor: "white",
    padding: "0 4px",
  },
});

const DynamicForm = () => {
  const [formData, setFormData] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { lead_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const Header = () => (
    <AppHeader position="fixed">
      <Toolbar>
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <img src={data} alt="Perform Solutions" height="55" />
            <Box textAlign="right">
              <Typography variant="subtitle1" color="primary">
                Lareina Hovanjec – 0413 719 306
              </Typography>
              <Typography variant="body2" color="textSecondary">
                info@perform-solutions.com.au
              </Typography>
              <Typography variant="body2" color="textSecondary">
                perform-solutions.com.au
              </Typography>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppHeader>
  );

  const Footer = () => (
    <AppFooter>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <img
            src="https://i.postimg.cc/Y2XLSRdG/performlogo1.png"
            alt="Perform Solutions"
            height="50"
          />
          <Box>
            <IconButton
              href="https://instagram.com/perform_solutions"
              target="_blank"
              sx={{ color: "#8d8dfd" }}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://linkedin.com/company/perform-solutions"
              target="_blank"
              sx={{ color: "#8d8dfd" }}
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </AppFooter>
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://performsolutions.my.salesforce-sites.com/services/apexrest/leadquotevalues?lead_id=${lead_id}`,
        );
        
        if (!response.data?.leadquotevalues?.[0]) {
          throw new Error(
            response.data || "Invalid data structure received from API"
          );
        }
        
        const rawData = response.data.leadquotevalues[0];
        const dropdownDefs = rawData.dropdown_values || {};
        const processedOptions = {};

        Object.entries(dropdownDefs).forEach(([key, value]) => {
          processedOptions[key] = value.split(",").map((opt) => opt.trim());
        });

        const processedData = { ...response.data };
        Object.entries(rawData).forEach(([section, sectionData]) => {
          if (typeof sectionData === "object" && sectionData !== null) {
            Object.entries(sectionData).forEach(([field, value]) => {
              if (processedOptions[field] && typeof value === "string") {
                processedData.leadquotevalues[0][section][field] = value
                  .split(";")
                  .filter(Boolean)
                  .map((v) => v.trim());
              }
            });
          }
        });

        delete processedData.leadquotevalues[0].dropdown_values;
        setDropdownOptions(processedOptions);
        setFormData(processedData);
        setError(null);
      } catch (err) {
        const errorMessage = err.message || "Failed to fetch data";
        setError(errorMessage);
        enqueueSnackbar(errorMessage, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (lead_id) {
      fetchData();
    }
  }, [lead_id, enqueueSnackbar]);

  const formatFieldName = (name) => {
    return name
      .replace(/_/g, " ")
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const getSortedSections = (leadData) => {
    return Object.entries(leadData).sort((a, b) => {
      const orderA =
        a[1]?.order !== undefined ? parseInt(a[1].order) : Infinity;
      const orderB =
        b[1]?.order !== undefined ? parseInt(b[1].order) : Infinity;
      return orderA - orderB;
    });
  };

  const handleInputChange = (section, field, value) => {
    setFormData((prev) => ({
      ...prev,
      leadquotevalues: [
        {
          ...prev.leadquotevalues[0],
          [section]: {
            ...prev.leadquotevalues[0][section],
            [field]: value,
          },
        },
      ],
    }));
  };

  const handleChipDelete = (section, field, valueToDelete) => {
    if (!editMode) return;
    const currentValue = formData.leadquotevalues[0][section][field];
    const newValues = Array.isArray(currentValue)
      ? currentValue.filter((v) => v !== valueToDelete)
      : [];
    handleInputChange(section, field, newValues);
  };

  const renderField = (section, fieldName, value) => {
    const isDropdown = Object.keys(dropdownOptions).includes(fieldName);

    if (isDropdown) {
      const currentValues = Array.isArray(value)
        ? value
        : value
        ? value
            .split(";")
            .filter(Boolean)
            .map((v) => v.trim())
        : [];

      return (
        <StyledFormControl fullWidth>
          <InputLabel>{formatFieldName(fieldName)}</InputLabel>
          <Select
            multiple
            value={currentValues}
            onChange={(e) =>
              handleInputChange(section, fieldName, e.target.value)
            }
            disabled={!editMode}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={
                      editMode
                        ? () => handleChipDelete(section, fieldName, value)
                        : undefined
                    }
                  />
                ))}
              </Box>
            )}
          >
            {dropdownOptions[fieldName].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      );
    }

    return (
        <TextField
          fullWidth
        label={formatFieldName(fieldName)}
        value={value || ""}
        onChange={(e) => handleInputChange(section, fieldName, e.target.value)}
          InputProps={{
          readOnly:
            !editMode ||
            (section === "Lead_information" && fieldName === "stage"),
          }}
        sx={{ mb: 2 }}
        />
    );
  };

  const renderFields = (section, data) => {
    if (!data || typeof data !== "object") {
      return (
        <Grid item xs={12}>
          <Alert severity="info">No data available for this section</Alert>
      </Grid>
      );
    }

    return Object.entries(data)
      .filter(([key]) => key !== "order")
      .map(([key, value]) => (
        <Grid item xs={12} sm={6} key={key}>
          {renderField(section, key, value)}
        </Grid>
    ));
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const submitData = JSON.parse(JSON.stringify(formData));
      submitData.leadquotevalues[0].dropdown_values = {};

      Object.entries(dropdownOptions).forEach(([key, values]) => {
        submitData.leadquotevalues[0].dropdown_values[key] = values.join(", ");
      });

      Object.entries(submitData.leadquotevalues[0]).forEach(
        ([section, sectionData]) => {
          if (typeof sectionData === "object" && sectionData !== null) {
            Object.entries(sectionData).forEach(([field, value]) => {
              if (Array.isArray(value)) {
                submitData.leadquotevalues[0][section][field] = value.join(";");
              }
            });
          }
        }
      );

      await axios.post(
        `https://performsolutions.my.salesforce-sites.com/services/apexrest/leadquotevalues?lead_id=${lead_id}`,
        submitData
      );

      enqueueSnackbar("Form submitted successfully!", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      setEditMode(false);
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to submit form";
      enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (!formData?.leadquotevalues?.[0]) {
    return (
      <>
        <Header />
        <ContentWrapper>
          <Container maxWidth="lg">
            <StyledPaper>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight={400}
                >
                  <CircularProgress sx={{ color: "#8d8dfd" }} />
                </Box>
              ) : (
        <Alert severity="info">No data available</Alert>
              )}
            </StyledPaper>
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }

  const leadData = formData.leadquotevalues[0];
  const sortedSections = getSortedSections(leadData);

  return (
    <>
      <Header />
      <ContentWrapper>
        <Container maxWidth="lg">
          <ScrollableContent>
            <StyledPaper>
              <Typography variant="h5" gutterBottom align="center">
                Request for Information
        </Typography>

              <Grid container spacing={3} sx={{ mb: 4,mt:2 }}>
                <Grid item xs={12} md={6}>
                  <Typography textAlign={"center"}>
                    <strong>Name:</strong> {leadData.Lead_information.firstname}{" "}
                    {leadData.Lead_information.lastname}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography textAlign={"center"}>
                    <strong>Company:</strong>{" "}
                    {leadData.Lead_information.company}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography textAlign={"center"}>
                    <strong>Email:</strong> {leadData.Lead_information.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography textAlign={"center"}>
                    <strong>Phone:</strong> {leadData.Lead_information.phone}
                  </Typography>
                </Grid>
              </Grid>

      <Box sx={{ mb: 3 }}>
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "#8d8dfd",
                    color: "white",
                    padding: "12px 20px",
                    borderRadius: "4px",
                  }}
                >
                  {formatFieldName(sortedSections[activeTab][0])}
        </Typography>
      </Box>
      
              <Grid container spacing={3}>
                {renderFields(
                  sortedSections[activeTab][0],
                  sortedSections[activeTab][1]
                )}
        </Grid>

              <Box
          sx={{ 
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  gap: 2,
          }}
        >
                <Tooltip title={editMode ? "Cancel Edit" : "Enable Edit"}>
                  <ActionIconButton onClick={() => setEditMode(!editMode)}>
                    {editMode ? <CancelIcon /> : <EditIcon />}
                  </ActionIconButton>
                </Tooltip>

                <Tooltip title="Previous Section">
                  <span>
                    <ActionIconButton
                      onClick={() => setActiveTab((prev) => prev - 1)}
                      disabled={activeTab === 0}
                    >
                      <PrevIcon />
                    </ActionIconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Next Section">
                  <span>
                    <ActionIconButton
                      onClick={() => setActiveTab((prev) => prev + 1)}
                      disabled={activeTab === sortedSections.length - 1}
                    >
                      <NextIcon />
                    </ActionIconButton>
                  </span>
                </Tooltip>

                {editMode && (
                  <Tooltip title="Save Changes">
                    <ActionIconButton
          onClick={handleSubmit}
          disabled={submitting}
        >
                      {submitting ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <SaveIcon />
                      )}
                    </ActionIconButton>
                  </Tooltip>
                )}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                {sortedSections.map((section, index) => (
                  <Box
                    key={section[0]}
          sx={{ 
                      width: 12,
                      height: 12,
                      borderRadius: "50%",
                      backgroundColor:
                        index === activeTab ? "#8d8dfd" : "#e0e0e0",
                      margin: "0 4px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor:
                          index === activeTab ? "#7272ff" : "#bdbdbd",
                      },
          }}
                    onClick={() => setActiveTab(index)}
                  />
                ))}
      </Box>
            </StyledPaper>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </ScrollableContent>
        </Container>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default DynamicForm;
