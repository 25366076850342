let BASE_URL;
let URL;
 

const server = 'PROD';

//Frontend URL String
if (server === 'PROD') {
  URL = 'https://app.securegateway.io/messagedetail/{%action%}/{%template%}/{%uid%}';
} 
else if (server === 'UAT') {
  URL = 'https://uat.securegateway.io/messagedetail/{%action%}/{%template%}/{%uid%}';
}
else if (server === 'LOCAL') {
  URL = 'http://localhost:3000/messagedetail/{%action%}/{%template%}/{%uid%}';
} 
else if (server === 'TEST') {
  URL = 'https://dev.securegateway.io/messagedetail/{%action%}/{%template%}/{%uid%}';
} 
else {
  URL = 'https://dev-upkeep.securegateway.io/messagedetail/{%action%}/{%template%}/{%uid%}';
}


//Backend COnnectivity
if (server === 'PROD') {
  BASE_URL = 'https://app-backend.securegateway.io';
} 
else if (server === 'UAT') {
  BASE_URL = 'https://uat-backend.securegateway.io';
}
else if (server === 'LOCAL') {
  BASE_URL = 'http://localhost:8081';
} 
else if (server === 'TEST') {
  BASE_URL = 'https://app-backend.securegateway.io';
}
else {
  BASE_URL = 'https://app-backend.securegateway.io';
}

export { BASE_URL, URL };
